
import {
  defineComponent,
  onMounted,
  reactive,
  computed,
  watch,
  ref,
} from "vue";
import OverallResult from "@/components/valored/survey-plus-report/OverallResult.vue";
// import Test from "@/components/valored/report/Test.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
import bus from "vue3-eventbus";

export default defineComponent({
  name: "SurveyPlusReport",
  components: {
    OverallResult,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );
    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    onMounted(() => {
      if (isAdmin.value) {
        store.dispatch(Actions.REQ_DETAIL_REPORT, route.params.id);
        store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);
        store.dispatch(Actions.REQ_AVERAGE_REPORT_LIST, route.params.id);
      } else {
        store.dispatch(Actions.REQ_DETAIL_REPORT);
        store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);
        store.dispatch(Actions.REQ_AVERAGE_REPORT_LIST);
      }

      if (current_yearly_period.value) {
        store.dispatch(Actions.REQ_AVERAGE_REPORT);
      }
    });

    watch(current_yearly_period, () => {
      store.dispatch(Actions.REQ_AVERAGE_REPORT);
    });
    return {};
  },
});
