
import {
  defineComponent,
  computed,
  reactive,
  onMounted,
  ref,
  watch,
} from "vue";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useRoute } from "vue-router";
import { Field } from "vee-validate";
import chartOption1 from "@/core/data/deffusioneChartOptions";
import chartOption2 from "@/core/data/amplitudeChartOptions";
import { useI18n } from "vue-i18n";

interface IFormData {
  employees_range: number;
  settore: number;
  fatturato: number;
  is_quoted: string;
  is_associated: string;
  company_type: string;
}

export default defineComponent({
  name: "surveyPlusOverallResult",
  components: { 
    Field,
  },
  setup() {
    const { locale } = useI18n();

    const store = useStore();
    const route = useRoute();

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const company_id = reactive(
      computed(() => store.getters.getCompanyYearList.azienda_id)
    );

    const rankings = reactive(
      computed(() => store.getters.getSurveyPlusRankings)
    );

    const resultScore = reactive(
      computed(() => store.getters.getSurveyPlusScoreResult)
    );

    const compareDetailResult = reactive(
      computed(() => store.getters.getSurveyPlusCompareDetailResult)
    );

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    let isLoading = false;

    onMounted(() => {
      setTimeout(() => {
        if (isAdmin.value) {
          store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, localStorage.getItem("to_year_admin"));
        } else {
          store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, localStorage.getItem("to_year"));
        }
      }, 1000);

      if (isAdmin.value) {
        store.dispatch(Actions.REQ_SURVEY_PLUS_RANKINGS).then(() => {
          getMyCompanyResult(company_id.value, localStorage.getItem("to_year_admin"));
        });
      } else {
        store.dispatch(Actions.REQ_SURVEY_PLUS_RANKINGS).then(() => {
          getMyCompanyResult(company_id.value, localStorage.getItem("to_year"));
        });
      }

      getCompareDetailReport(formDataForCompareDetail.value);
    });

    const selectOptions = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const formDataForCompareDetail = ref<IFormData>({
      employees_range: 0,
      settore: 0,
      fatturato: 0,
      is_quoted: "ALL",
      is_associated: "ALL",
      company_type: "ALL",
    });

    const seriesForGraph2 = ref([
      {
        name: translate("SURVEY_PLUS_LABEL_YOUR_COMPANY"),
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        name: translate("SURVEY_PLUS_LABEL_AVERAGE_COMPANIES"),
        data: [0, 0, 0, 0, 0, 0],
      },
    ]);

    const deffusioneChartOptions = reactive(computed(() => chartOption1));
    const amplitudeChartOptions = reactive(computed(() => chartOption2));

    const radarChartOptions = {
      chart: {
        height: 350,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 2,
        colors: ["#003A5D", "#10BEC5"],
      },
      xaxis: {
        categories: [
          translate("SURVEY_PLUS_LABEL_GENRE"),
          translate("SURVEY_PLUS_LABEL_AGE"),
          translate("SURVEY_PLUS_LABEL_DISABILIY"),
          translate("SURVEY_PLUS_LABEL_CULTURE"),
          translate("SURVEY_PLUS_LABEL_BACKGROUND"),
          translate("SURVEY_PLUS_LABEL_SEX"),
        ],
      },
      fill: {
        opacity: 0.7,
        colors: ["#003A5D", "#10BEC5"],
      },
      markers: {
        size: 0,
      },
      scale: {
        y: {
          min: 0,
          max: 100,
        },
        r: {
          grid: {
            circular: true,
          },
          beginAtZero: true,
        },
      },
      yaxis: {
        max: 100,
        min: 0,
      },
      legend: {
        show: true,
        floating: true,
        fontSize: "16px",
        position: "left",
        offsetX: 0,
        offsetY: 20,
        horizontalAlign: "center",
        labels: {
          useSeriesColors: false,
          colors: ["#003A5D", "#10BEC5"],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          fillColors: ["#003A5D", "#10BEC5"],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
    };
    const deffusioneGraph = ref([0, 0]);
    const amplitudeGraph = ref([0, 0]);

    watch(current_yearly_period, () => {
      console.log("current_yearly_period watch");
      if (isAdmin.value) {
        getMyCompanyResult(company_id.value, localStorage.getItem("to_year_admin"));
      } else {
        getMyCompanyResult(company_id.value, localStorage.getItem("to_year"));
      }
      getCompareDetailReport(formDataForCompareDetail.value);
    });

    watch(formDataForCompareDetail.value, (val: IFormData) => {
      console.log("formDataForCompareDetail watch");
      getCompareDetailReport(val);
    });

    const getMyCompanyResult = (company_id, year) => {
      isLoading = true;
      store
        .dispatch(Actions.REQ_SURVEY_PLUS_SCORE_RESULT, {
          company_id: company_id,
          year: year
        })
        .then(() => {
          const score = Math.floor(parseFloat(resultScore.value.ranking));
          const sex = parseFloat(
            parseFloat(resultScore.value.sex_point).toFixed(1)
          );
          const age = parseFloat(
            parseFloat(resultScore.value.age_point).toFixed(1)
          );
          const disability = parseFloat(
            parseFloat(resultScore.value.disability_point).toFixed(1)
          );
          const culture = parseFloat(
            parseFloat(resultScore.value.culture_point).toFixed(1)
          );
          const background = parseFloat(
            parseFloat(resultScore.value.background_point).toFixed(1)
          );
          const lgbt = parseFloat(
            parseFloat(resultScore.value.lgbt_point).toFixed(1)
          );

          rankings.value.forEach((item) => {
            const rangeArr = item.attributes.range.split("-");
            const fromRange = parseInt(rangeArr[0].replace(/\s/g, ""));
            const toRange = parseInt(rangeArr[1].replace(/\s/g, ""));
            if (score >= fromRange && score <= toRange) {
              item.is_active = true;
            } else item.is_active = false;
          });
          if (resultScore.value.diffusion !== null)
            deffusioneGraph.value[0] = parseFloat(
              parseFloat(resultScore.value.diffusion).toFixed(1)
            );
          if (resultScore.value.amplitude !== null)
            amplitudeGraph.value[0] = parseFloat(
              parseFloat(resultScore.value.amplitude).toFixed(1)
            );
          seriesForGraph2.value[0].data = [
            sex,
            age,
            disability,
            culture,
            background,
            lgbt,
          ];
          isLoading = false;
        });
    };

    const getCompareDetailReport = (data: IFormData) => {
      store
        .dispatch(Actions.REQ_SURVEY_PLUS_COMPARE_DETAIL_REPORT, {
          data: {
            ...data,
            comparison_year: current_yearly_period.value,
          },
          user_id: route.params.id ? route.params.id : "",
        })
        .then(() => {
          seriesForGraph2.value[1].data = [
            parseFloat(parseFloat(compareDetailResult.value.sex).toFixed(1)),
            parseFloat(parseFloat(compareDetailResult.value.age).toFixed(1)),
            parseFloat(
              parseFloat(compareDetailResult.value.disability).toFixed(1)
            ),
            parseFloat(
              parseFloat(compareDetailResult.value.culture).toFixed(1)
            ),
            parseFloat(
              parseFloat(compareDetailResult.value.background).toFixed(1)
            ),
            parseFloat(parseFloat(compareDetailResult.value.lgbt).toFixed(1)),
          ];
          if (compareDetailResult.value.diffusion !== null)
            deffusioneGraph.value[1] = parseFloat(
              parseFloat(compareDetailResult.value.diffusion).toFixed(1)
            );
          if (compareDetailResult.value.amplitude !== null)
            amplitudeGraph.value[1] = parseFloat(
              parseFloat(compareDetailResult.value.amplitude).toFixed(1)
            );
        });
    };

    function formatRankingLabel(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    function onClickContactUs() {
      const anchorElement = document.createElement("a");
      if (locale.value === "it") {
        anchorElement.href = "mailto:inclusionimpactindexplus@valored.it?subject=Assistenza%20piattaforma%20Inclusion%20Impact%20Index%20Plus";
      } else {
        anchorElement.href = "mailto:inclusionimpactindexplus@valored.it?subject=Support%20for%20Inclusion%20Impact%20Index%20Plus";
      }
      anchorElement.target = "_blank";
      anchorElement.click();
    }

    return {
      translate,
      current_yearly_period,
      rankings,
      resultScore,
      translateKey,
      seriesForGraph2,
      deffusioneChartOptions,
      amplitudeChartOptions,
      radarChartOptions,
      //list,
      isLoading,
      selectOptions,
      formDataForCompareDetail,
      deffusioneGraph,
      amplitudeGraph,
      compareDetailResult,
      formatRankingLabel,
      onClickContactUs
    };
  },
});
