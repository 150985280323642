const chartOptions = {
  chart: {
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      offsetY: 100,
      startAngle: -90,
      endAngle: 90,
      hollow: {
        margin: 15,
        size: "50%",
        background: "transparent",
        image: undefined,
      },
      track: {
        background: "#ffffff",
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
    },
  },
  colors: ["#00305d", "#00c0d0"],
  labels: ["La tua azienda", "Altre Aziende"],
  legend: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: true,
        },
      },
    },
  ],
};

export default chartOptions;
